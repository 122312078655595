<style lang="scss" scoped>
/deep/ .el-container {
	width: 100%;
	height: 100% !important;
}

.info {
	display: flex;

	span {
		line-height: 41px;
	}
}

/deep/.el-dialog__title {
	display: flex;
	flex-direction: row;
}

/deep/.el-dialog__body {
	padding: 0px;
}

.gutter {
	width: 200px;
}

.upload-data {
	float: left;
}
</style>

<template>
	<div class="app-container">
		<el-card>
			<div class="info mb10">
				<span>角色：</span>
				<el-select v-model="infoForm.roleid" placeholder="请选择" clearable>
					<el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
				<span>院系：</span>
				<el-select v-model="infoForm.depid" placeholder="请选择" clearable @change="changeSystem">
					<el-option v-for="item in systemList" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>

				<span>班号：</span>
				<el-select v-model="infoForm.classid" placeholder="请选择" clearable @change="changeClass" class="w180">
					<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>

				<!-- <span>有无照片：</span>
				<el-select v-model="infoForm.photeid" placeholder="请选择" style="width: 130px;height: 48px;margin:0 10px" clearable>
				  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
				</el-select> -->
			</div>
			<div class="info">
				<span>姓名/工号：</span>
				<!-- 搜索 添加 -->
				<el-input placeholder="姓名或工号 关键字" class="w180" v-model="searchName" clearable></el-input>

				<div>
					<el-button type="primary" @click="goSearch(infoForm)">搜索</el-button>
					<el-button type="primary" @click="addInfo" plain class="ml10">新增</el-button>
					<!-- 模板下载 -->
					<el-button type="primary" plain class="ml10" @click="downloadTemplate()">模板下载</el-button>
					<!-- 导入数据信息 -->
					<!-- <el-upload class="upload-data" :action="imgBaseUrl + '/api/import/student'" :on-success="upload" :show-file-list="false"> -->
					<el-upload class="upload-data" :action="imgBaseUrl + '/api/import/importTeacherData'"
						:on-success="upload" :on-error="uploadError" :show-file-list="false">
						<el-button type="primary" plain class="ml10">导入</el-button>
					</el-upload>
				</div>
			</div>

			<el-table class="mgt16" ref="multipleTable" :data="tableData" tooltip-effect="dark"
				element-loading-text="Loading" border fit highlight-current-row>
				<el-table-column label="序号" align="center" type="index" width="60"></el-table-column>

				<el-table-column prop="name" align="left" label="姓名">
					<!-- 使用 scope 显示 v-html 内容 -->
					<template slot-scope="scope">
						<span v-html="scope.row.nameRed" v-bind:title="scope.row.id"></span>
					</template>
				</el-table-column>

				<el-table-column prop="studentnum" align="left" label="工号">
					<!-- 使用 scope 显示 v-html 内容 -->
					<template slot-scope="scope">
						<span v-html="scope.row.studentnumRed" v-bind:title="scope.row.id"></span>
					</template>
				</el-table-column>

				<el-table-column prop="sex" align="left" label="性别"></el-table-column>
				<el-table-column prop="company" align="left" label="职务"></el-table-column>
				<el-table-column prop="rname" align="left" label="角色"></el-table-column>
				<el-table-column prop="username" align="left" label="电话"></el-table-column>
				<el-table-column prop="dname" align="left" label="系部"></el-table-column>
				<el-table-column prop="cname" align="left" label="班级"></el-table-column>
				<el-table-column prop="classnum" align="left" label="班号"></el-table-column>
				<el-table-column id="clumn_9" property="address" label="操作" align="center" width="150">
					<template slot-scope="scope">
						<div style="display: flex; justify-content: center">
							<el-button type="primary" size="small" @click="handleView(scope.row)">编辑</el-button>
							<el-button type="danger" size="small" @click="handleDelete(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mgt16" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="page" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>

			<el-dialog v-if="dialogFormVisible" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%"
				minWidth="600px" @close="cancelDialogFormShow()">
				<teacherAddComponent ref="dialogForm" @changeDialogFormVisible="changeDialogFormVisible($event)"
					:dialogStatus="dialogStatus" />
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
import * as api from "@/api/users";
import {
	getHttpUrl
} from "@/utils/request";
import teacherAddComponent from "@/views/add/teacherAddComponent";
export default {
	components: {
		teacherAddComponent,
	},
	data() {
		return {
			imgBaseUrl: getHttpUrl(),
			page: 1,
			pageSize: 10,
			form: {},
			infoForm: {},
			idis: "",
			searchName: "", // 姓名

			formLabelWidth: "120px",
			Authorization: "",
			dialogFormVisible: false,
			dialogStatus: "",
			textMap: {
				update: "编辑",
				create: "新增",
				view: "详情",
			},

			listQuest: {
				page: 1,
				pageSize: 10,
			},
			roleList: [{
				id: 1,
				name: "管理员"
			},
			{
				id: 2,
				name: "班主任"
			},
			{
				id: 3,
				name: "系主任"
			},
			{
				id: 4,
				name: "校负责领导"
			},
			{
				id: 6,
				name: "老师"
			},
			],
			systemList: [], //系列表
			classList: [], //班级列表
			total: 0,
			options: [{
				label: "有",
				value: 1,
			},
			{
				label: "没有",
				value: 0,
			},
			],
			roles: [],
			tableData: [],
			input: "",
		};
	},

	created() {
		this.loadData();
		this.getData();
	},
	methods: {
		// 打开 新增 
		addInfo() {
			this.dialogStatus = "create";
			this.dialogFormVisible = true;
		},

		upload(response) {
			if (response.status === -1) {
				// this.$message.error(response.msg);
				this.$confirm(response.msg, '提示', {
					confirmButtonText: '确定',
					type: 'error'
				}).then(() => { }).catch(() => { });
			} else {
				this.$message({ message: '数据导入成功', type: 'success' });
				this.loadData();
			}
		},

		/**
		 * 文件上传失败回调
		 */
		uploadError(err) {
			let me = this;
			console.log("err====== ", err);
			// me.$message.error("数据导入失败！");
			me.$confirm('数据导入失败！', '提示', {
				confirmButtonText: '确定',
				type: 'error'
			}).then(() => { }).catch(() => { });
		},

		handleDelete(row) {
			this.$confirm("确认删除？").then(() => {
				api.deleteStuArgument(row.id).then((res) => {
					if (res.status == 1) {
						this.loadData();
						this.$message.success(res.msg);
					} else {
						this.$message.success(res.msg);
					}
				});
			});
		},
		// 打开 编辑
		handleView(row) {
			this.dialogStatus = "update";
			this.dialogFormVisible = true;
			this.form = row;
			this.$nextTick(() => {
				this.$refs.dialogForm.showData(row);
			});
		},
		indexMethod(index) {
			return index * 1;
		},
		cancelDialogFormShow() {
			// alert("点击空白区域隐藏");
			this.$refs.editFormRef.resetFields();
			this.dialogFormVisible = false;
			this.loadData();
		},
		changeDialogFormVisible(data) {
			this.dialogFormVisible = data;
			this.loadData();
		},
		// 切换系部
		changeSystem(id) {
			api.getCardArgumentDetail({
				depid: id
			}).then((res) => {
				let classList = res.data.records.filter((item) => item.depid === id);
				this.classList = classList;
				this.classid = null;
				if (classList != null && classList.length > 0) {
					this.classid = classList[0].id;
				}
			});
		},
		// 切换班级
		changeClass(id) {
			this.classid = id;
		},
		getType() {
			api.getCurrUserItem().then((res) => {
				this.idis = res.data.rname;
			});
		},

		// 初始化数据
		getData() {
			let params = {
				page: 1,
				pageSize: 10,
			};
			// 院系接口
			api.getSystemList(params).then((res) => {
				this.systemList = res.data.records;
			});
		},

		loadData() {
			this.getType();
			api.getUserList({
				page: this.page,
				pageSize: this.pageSize,
				classid: "",
				identity: 1, // 类型：0为老师 1为学生
				searchName: this.searchName, // 姓名/工号
			})
				.then((res) => {
					if (res.status == 1) {
						// 关键字标红
						for (let i = 0; i < res.data.rows.length; i++) {
							res.data.rows[i].nameRed = res.data.rows[i].name;
							res.data.rows[i].studentnumRed = res.data.rows[i].studentnum;
							if (this.searchName.length > 0) {
								if (res.data.rows[i].name.indexOf(this.searchName) >= 0) {
									// 姓名标红
									var tempList1 = res.data.rows[i].name.split(this.searchName);
									res.data.rows[i].nameRed = tempList1.join(
										'<span style="color:red;">' + this.searchName + "</span>"
									);
								}
								if (res.data.rows[i].studentnum.indexOf(this.searchName) >= 0) {
									// 工号标红
									var tempList2 = res.data.rows[i].studentnum.split(this.searchName);
									res.data.rows[i].studentnumRed = tempList2.join(
										'<span style="color:red;">' + this.searchName + "</span>"
									);
								}
							}
						}
						// 赋值
						this.tableData = res.data.rows;
						this.total = res.data.total;
					} else {
						this.$message.error(res.msg);
					}
				});
			// api.getSystemList({
			// 		page: 1,
			// 		pageSize: 1000,
			// 		classid: "",
			// 		roleid: "",
			// 	})
			// 	.then((res) => {
			// 		this.systemList = res.data.records;
			// 	});
		},
		handleClose(done) {
			done();
		},
		handleSizeChange(val) {
			this.pageSize = val;
			this.loadData();
		},
		handleCurrentChange(val) {
			this.page = val;
			this.loadData();
		},
		handleEdit(row) {
			this.getRole();
			this.form.id = row.id;
			this.roles.roleName = row.role_name;
			this.dialogFormVisible = !this.dialogFormVisible;
		},
		goSearch(form) {
			form.page = this.listQuest.page;
			form.pageSize = this.listQuest.pageSize;
			form.identity = 1; // 类型：0为老师 1为学生
			form.searchName = this.searchName; // 姓名
			api.getUserList(form).then((res) => {
				if (res.status == 1) {
					// 关键字标红
					for (let i = 0; i < res.data.rows.length; i++) {
						res.data.rows[i].nameRed = res.data.rows[i].name;
						res.data.rows[i].studentnumRed = res.data.rows[i].studentnum;
						if (this.searchName.length > 0) {
							if (res.data.rows[i].name.indexOf(this.searchName) >= 0) {
								// 姓名标红
								var tempList1 = res.data.rows[i].name.split(this.searchName);
								res.data.rows[i].nameRed = tempList1.join(
									'<span style="color:red;">' + this.searchName + "</span>"
								);
							}
							if (res.data.rows[i].studentnum.indexOf(this.searchName) >= 0) {
								// 工号标红
								var tempList2 = res.data.rows[i].studentnum.split(this.searchName);
								res.data.rows[i].studentnumRed = tempList2.join(
									'<span style="color:red;">' + this.searchName + "</span>"
								);
							}
						}
					}
					// 赋值
					this.tableData = res.data.rows;
					this.total = res.data.total;
				} else {
					this.$message.error(res.msg);
				}
			});
		},

		/**
		 * 模板下载
		 */
		downloadTemplate() {
			window.location.href = this.imgBaseUrl + "/api/import/exportTemplate?rowType=2"
		}

	},
};
</script>

<template>
	<div>
		<el-row :gutter="1" type="flex" justify="space-between">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }">
				<hr style="border: 0.5px solid blue; margin-bottom: 20px" />
				<el-form ref="form" :model="form" :inline="true" label-position="left" label-width="100px" :rules="rules1">
					<div class="techBox pb20" style="max-height:500px">
						<div class="div1 pb10" style="display: flex; flex-wrap: wrap">
							<el-form-item label="手机号" prop="username" class="mb5 pl10">
								<el-input v-model="form.username" maxlength="11"></el-input>
							</el-form-item>
							<el-form-item label="密码" prop="password" class="mb5">
								<el-input type="password" v-model="form.password"></el-input>
							</el-form-item>
							<el-form-item label="姓名" prop="name" class="mb5">
								<el-input v-model="form.name"></el-input>
							</el-form-item>
							<el-form-item label="角色" prop="rname" class="mb5">
								<el-select v-model="form.rname" placeholder="请选择" @change="roseChange">
									<el-option v-for="item in roleList" :key="item.id" :label="item.name"
										:value="item.id" />
								</el-select>
							</el-form-item>
							<el-form-item label="工号" prop="studentnum" class="mb5">
								<el-input v-model="form.studentnum"> </el-input>
							</el-form-item>

							<el-form-item label="性别" prop="sex" class="mb5">
								<el-select v-model="form.sex" placeholder="请选择">
									<el-option v-for="item in sexList" :key="item.id" :label="item.label"
										:value="item.label" />
								</el-select>
							</el-form-item>

							<el-form-item label="职务" prop="company" class="mb5">
								<el-input v-model="form.company"></el-input>
							</el-form-item>
							<el-form-item label="职称" prop="companyname" class="mb5">
								<el-input v-model="form.companyname"></el-input>
							</el-form-item>

							<el-form-item label="教龄" prop="teachage" class="mb5">
								<el-input v-model="form.teachage"></el-input>
							</el-form-item>
							<el-form-item label="民族" prop="nation" class="mb5">
								<el-input v-model="form.nation"></el-input>
							</el-form-item>
						</div>

						<div class="div2 pb10">
							<el-form-item label="照片" prop="photo" class="mb5">
								<img class="img1" :src="imgBaseUrl + form.photo + imgRightSub" alt=""
									@click="toSelectTeacherPhotoImg();" />
								<el-upload ref="myUploadTeacherPhoto"
									:action="imgBaseUrl + '/api/sysassessment/uploadAvator'" :data="{
										depId: this.form.depid,
										classId: this.form.classid,
										studentNum: this.form.studentnum,
										roleId: this.form.roleid,
									}" :limit="1" multiple accept=".jpeg,.jpg,.png" :on-success="onSuccess" :on-remove="onRemove"
									:disabled="!form.studentnum || !form.rname">
									<el-button id="btnSelectTeacherPhoto" size="small" type="primary"
										:disabled="!form.studentnum || !form.rname">上传照片</el-button>
								</el-upload>
								<p style="color: red">*必须优先填写工号和角色才可上传</p>
							</el-form-item>

							<el-form-item label="系部" prop="dname" class="mb5">
								<el-select v-model="didschool" placeholder="请选择" @change="depidChanged">
									<el-option v-for="item in systemList" :key="item.id" :label="item.name"
										:value="item.id" />
								</el-select>
							</el-form-item>
							<el-form-item label="班级" prop="cname" class="mb5">
								<el-select v-model="form.cname" placeholder="请先选择系别" @change="classChange">
									<el-option v-for="item in cnameList" :key="item.id" :label="item.name"
										:value="item.id" />
								</el-select>
							</el-form-item>
							<el-form-item label="班号" prop="classnum" class="mb5">
								<el-input v-model="form.classnum" disabled></el-input>
							</el-form-item>

							<el-form-item label="身份证" prop="pid" class="mb5">
								<el-input v-model="form.pid"></el-input>
							</el-form-item>

							<el-form-item label="政治面貌" prop="political" class="mb5">
								<el-input v-model="form.political"></el-input>
							</el-form-item>
							<el-form-item label="出生日期" prop="birthday" class="mb5">
								<span class="demonstration"></span>
								<el-date-picker v-model="form.birthday" type="date" placeholder="选择日期时间" format="yyyy-MM-dd"
									value-format="yyyy-MM-dd">
								</el-date-picker>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="dialog-footer mt10 pt10 mb15 bt1_blue textCenter">
					<el-button @click="cancel()"> 取消 </el-button>
					<el-button type="primary" @click="dialogStatus == 'create' ? saveTeacherAdd() : saveTeacherEdit()"
						:disabled="!form.name ||
							!form.rname ||
							!form.studentnum ||
							!form.company ||
							!form.username ||
							!form.sex">
						确认
					</el-button>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import * as api from "@/api/users";
import {
	getHttpUrl
} from "@/utils/request";
import {
	getNodeItemByID
} from "@/utils/mine.js";
export default {
	components: {},
	props: ["dialogStatus"],
	data() {
		return {
			rules1: {
				username: [{
					required: true,
					trigger: "blur",
					message: "手机号必填"
				}],
				password: [{
					required: true,
					trigger: "blur",
					message: "密码必填"
				}],
				name: [{
					required: true,
					trigger: "blur",
					message: "姓名4-16位字符必填"
				},],
				rname: [{
					required: true,
					trigger: "blur",
					message: "角色必填"
				}],
				studentnum: [{
					required: true,
					trigger: "blur",
					message: "工号必填"
				}],
				company: [{
					required: true,
					trigger: "blur",
					message: "职务必填"
				}],
				sex: [{
					required: true,
					trigger: "change",
					message: "性别必填"
				}],
			},
			returnImgUrl: "",
			props: ["inputName", "visible.sync"],

			imgBaseUrl: getHttpUrl(),
			imgRightSub: "",

			readonly: true,
			typeList: [],
			idis: "",
			value1: "",
			systemList: [],
			cnameList: [],
			options: [],

			// 初始化 图片
			form: {
				depid: "",
				cname: "",
				name: "",
				studentnum: "",
				company: "",
				sex: "",
				photo: "/noHead.png",
			},

			didschool: "",
			unitList: [],
			groupList: [],
			sexList: [{
				id: 0,
				label: "女",
			},
			{
				id: 1,
				label: "男",
			},
			],
			roleList: [{
				id: 1,
				name: "管理员"
			},
			{
				id: 2,
				name: "班主任"
			},
			{
				id: 3,
				name: "系主任"
			},
			{
				id: 4,
				name: "校负责领导"
			},
			{
				id: 6,
				name: "老师"
			},
			],
			infoFlag: true,
			userTypeList: [],
		};
	},
	computed: {},
	watch: {},
	created() {
		this.load();
	},
	mounted() { },
	methods: {
		// 点击 网页图片，也能选 图片文件
		toSelectTeacherPhotoImg() {
			getNodeItemByID("btnSelectTeacherPhoto").click();
		},
		// 生成一个随机数
		randomNumImgRSub() {
			this.imgRightSub = "?t=" + Math.random().toString(36).slice(2);
		},
		roseChange(e) {
			this.form.roleid = e;
		},
		classChange(e) {
			let me = this;
			console.log("====== **** -------", e);
			me.cnameList.forEach(function (value) {
				if (value.id == e) {
					console.log("====== **** -------", value);
					me.form.cname = value.name;
					me.form.classnum = value.classnum;
					me.form.classid = value.id;
				}
			});
			console.log("====== **** -------", e);
		},
		// 上传成功
		onSuccess(response) {
			this.randomNumImgRSub();
			// 在每一次上传请求结束后清除缓存，否则无法第二次选图
			this.$refs['myUploadTeacherPhoto'].clearFiles();
			this.returnImgUrl = response.data;
			this.$set(this.form, "photo", response.data);
		},
		onRemove(file) {
			console.log(file);
			this.returnImgUrl = "";
			this.$set(this.form, "photo", "");
		},
		// getphoto() {
		//   api.getPhoto().then(res => {
		//     console.log(res);
		//   });
		// },
		// handleChange(f){
		//   if(f){
		//   }
		// },
		// elInFile(f, file) {
		//   // this.staticFiles.push(f);
		//   // this.mscUrl = URL.createObjectURL(file[0].raw);
		//   // this.mscName = file[0].name;
		//   let formdata = new FormData();
		//   formdata.append(file, f.raw);

		//   api.transPhoto(formdata).then((res) => {
		//     this.returnImgUrl = res.data;
		//     // this.$message({
		//     //   showClose: true,
		//     //   message: "头像上传成功",
		//     //   type: "success"
		//     // });
		//     this.form.photo = this.returnImgUrl;
		//   });
		// },
		beforeAvatarUpload() { },
		depidChanged(id) {
			// 所选 系部ID
			this.form.depid = id;
			var systemList = this.systemList;
			for (let temp1 = 0; temp1 < systemList.length; temp1++) {
				if (systemList[temp1].id == this.form.depid) {
					// 所选 系部名称
					this.form.dname = systemList[temp1].name;
					break;
				}
			}
			// 刷新 系部 下的 班级 列表
			this.refreshClassList();
		},
		// 刷新班级列表数据
		refreshClassList() {
			api.getCardArgumentDetail({
				page: 1,
				pageSize: 10,
				depid: this.form.depid,
			})
				.then((res) => {
					let arr = res.data.records;
					let arrclass = [];
					for (let i = 0; i < arr.length; i++) {
						if (arr[i].depid === this.didschool) {
							arrclass.push(arr[i]);
						}
					}
					this.cnameList = arrclass;
					// ------------- 改选班级（可能不存在了）-------------
					var classid = this.form.classid;
					if (classid == null || classid <= 0) {
						// 之前没有选班级
						if (this.cnameList.length > 0) {
							classid = this.cnameList[0].id
							this.form.cname = this.cnameList[0].name;
							this.form.classnum = this.cnameList[0].classnum;
							this.form.classid = this.cnameList[0].id;
						}
					} else {
						var ifFind = false;
						for (var temp = 0; temp < this.cnameList.length; temp++) {
							if (classid == this.cnameList[temp].id) {
								// 选中 上一次的 班级
								ifFind = true;
								this.form.cname = this.cnameList[temp].name;
								this.form.classnum = this.cnameList[temp].classnum;
								this.form.classid = this.cnameList[temp].id;
								break;
							}
						}
						if (ifFind == false) {
							if (this.cnameList.length > 0) {
								// 选中 第一个 班级
								this.form.cname = this.cnameList[0].name;
								this.form.classnum = this.cnameList[0].classnum;
								this.form.classid = this.cnameList[0].id;
							} else {
								// 没有 可选 班级
								this.form.cname = "";
								this.form.classnum = "";
								this.form.classid = -1;
							}
						}
					}
				});
			// this.load();
		},
		load() {
			api.getCurrUserItem().then((res) => {
				this.idis = res.data.rname;
			});
			api.getSystemList({
				page: 1,
				pageSize: 1000,
			})
				.then((res) => {
					this.systemList = res.data.records;
					// 刷新 系部 下的 班级 列表
					this.refreshClassList();
				});
		},
		cancel() {
			this.$emit("changeDialogFormVisible", false);
		},
		getTrue() {
			return true;
		},
		getFalse() {
			return false;
		},
		// 保存 数据 add
		saveTeacherAdd() {
			let r = {};
			r.id = this.form.rname;
			let a = [];
			a.push(r);
			this.form.roles = a;
			this.form.status = 1;
			this.form.identity = 1;
			this.form.roleid = this.form.rname;
			this.form.photo = this.returnImgUrl;
			api.transAddInfo(this.form).then(res => {
				if (res.status == 1) {
					this.$message({
						message: "添加老师成功",
						type: "success",
					});
					this.$emit("changeDialogFormVisible", false);
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// 保存 数据 Edit
		saveTeacherEdit() {
			api.updateStuArgument(this.form.id, this.form).then(() => {
				this.$emit("changeDialogFormVisible", false);
			});
		},
		addNew(temp) {
			this.form = temp;
		},
		showData(record) {
			this.form = record;
			this.didschool = record.depid;
		},
	},
};
</script>
<style lang="scss" scoped>
.el-row {
	height: auto;
}

.el-col {
	width: 100%;
}

.el-dialog {
	height: 354px;
	width: 669px;
}

.img1 {
	width: auto;
	height: 164px;
	cursor: pointer;
}

.div2 {
	flex: 1;
	min-width: 340px;
}

.div1 {
	flex: 1;
	height: 188px;
	min-width: 340px;
	justify-content: center;

	/deep/.el-form-item {
		height: 40px;
		min-width: 340px;
	}

	/deep/.el-form-item label {
		text-align: right;
	}
}

.techBox {
	display: flex;
	overflow: auto;
	max-height: 800px;
}

.dialog-footer {
	margin-bottom: 20px;
}
</style>
